import { TribeContext } from "@/components/context/tribe-wrapper";
import { hasData } from "@/lib/utils/hasData";
import { useContext, useEffect, useState } from "react";


export default function LoadingRotary({
     classSize='h-32 w-32 '
    ,classAnimate='animate-spin-slow'
    ,bgColor='transparent'
    ,centerColor='#111827'
    ,holeColor='#111827'
    ,pointerColor='#ff0000'
    ,dialerColor='#ffffff'
    ,size='large'
    ,title=null
}) {

    const ssr_data_tribe = useContext(TribeContext);

    const [vartitle,set_vartitle] = useState(title)
    useEffect(()=>{

        //@ts-ignore
        set_vartitle(hasData(title) ? title : ssr_data_tribe?.isLoading ? `Loading...` : `${ssr_data_tribe?.brand_name} ${ssr_data_tribe?.brand_supporter}`)

    },[title])

    return (<>

                        <div
                            className={`${classSize} relative `}
                        >    



                                    {size=='large' &&
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{title}</title>
                                            <rect data-name="bg" width="64" height="64" rx="4.35" style={{fill: bgColor}}/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <g data-name="holes">
                                                <path data-name="dialer" d="M32,4.8A27.58,27.58,0,1,0,59.57,32.38,27.58,27.58,0,0,0,32,4.8ZM46.65,16.35A3.42,3.42,0,1,1,46,21.14,3.41,3.41,0,0,1,46.65,16.35Zm.89,16.24A15.56,15.56,0,1,1,32.19,16.82,15.54,15.54,0,0,1,47.54,32.59Zm-9.3-21a3.42,3.42,0,1,1,1.54,4.58A3.41,3.41,0,0,1,38.24,11.57Zm-6.18-4a3.42,3.42,0,1,1-3.46,3.37A3.41,3.41,0,0,1,32.06,7.55ZM21.28,10a3.42,3.42,0,1,1-1.66,4.54A3.43,3.43,0,0,1,21.28,10Zm-8.66,6.87a3.41,3.41,0,1,1,.47,4.81A3.42,3.42,0,0,1,12.62,16.84ZM7.79,26.78a3.42,3.42,0,1,1,2.52,4.13A3.44,3.44,0,0,1,7.79,26.78Zm0,11a3.42,3.42,0,1,1,4.06,2.63A3.43,3.43,0,0,1,7.76,37.83Zm9.56,10.58a3.42,3.42,0,1,1,.61-4.8A3.42,3.42,0,0,1,17.32,48.41Zm8.41,4.77a3.42,3.42,0,1,1-1.54-4.58A3.42,3.42,0,0,1,25.73,53.18Zm6.18,4a3.42,3.42,0,1,1,3.46-3.37A3.43,3.43,0,0,1,31.91,57.21Zm10.78-2.43a3.42,3.42,0,1,1,1.66-4.54A3.42,3.42,0,0,1,42.69,54.78Zm8.66-6.86a3.42,3.42,0,1,1-.47-4.81A3.42,3.42,0,0,1,51.35,47.92Z" transform="translate(0 0)" style={{fill:dialerColor}}/>
                                                <circle data-name="hole" cx="32.01" cy="10.96" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="22.72" cy="13.07" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="15.26" cy="19" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.11" cy="27.59" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.1" cy="37.12" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="15.23" cy="45.71" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="22.67" cy="51.66" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="31.96" cy="53.79" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="41.25" cy="51.68" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="48.71" cy="45.75" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="48.74" cy="19.05" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="41.3" cy="13.1" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="center" cx="31.98" cy="32.38" r="15.56" style={{fill:centerColor}}/>
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <path data-name="pointer"  d="M32,32.39l25.83-10.6a28,28,0,0,1,0,21.22Z" transform="translate(0 0)" style={{fill:pointerColor}}/>
                                        </svg>

                                    </>}

                                        




                                    {size=='64' &&
                                    <>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{title}</title>
                                            <rect data-name="bg" width="64" height="64" rx="4.35" style={{fill: bgColor}}/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <g data-name="holes">
                                                <path data-name="dialer" d="M32,4.8A27.58,27.58,0,1,0,59.57,32.38,27.58,27.58,0,0,0,32,4.8ZM46.65,16.35A3.42,3.42,0,1,1,46,21.14,3.41,3.41,0,0,1,46.65,16.35Zm.89,16.24A15.56,15.56,0,1,1,32.19,16.82,15.54,15.54,0,0,1,47.54,32.59Zm-9.3-21a3.42,3.42,0,1,1,1.54,4.58A3.41,3.41,0,0,1,38.24,11.57Zm-6.18-4a3.42,3.42,0,1,1-3.46,3.37A3.41,3.41,0,0,1,32.06,7.55ZM21.28,10a3.42,3.42,0,1,1-1.66,4.54A3.43,3.43,0,0,1,21.28,10Zm-8.66,6.87a3.41,3.41,0,1,1,.47,4.81A3.42,3.42,0,0,1,12.62,16.84ZM7.79,26.78a3.42,3.42,0,1,1,2.52,4.13A3.44,3.44,0,0,1,7.79,26.78Zm0,11a3.42,3.42,0,1,1,4.06,2.63A3.43,3.43,0,0,1,7.76,37.83Zm9.56,10.58a3.42,3.42,0,1,1,.61-4.8A3.42,3.42,0,0,1,17.32,48.41Zm8.41,4.77a3.42,3.42,0,1,1-1.54-4.58A3.42,3.42,0,0,1,25.73,53.18Zm6.18,4a3.42,3.42,0,1,1,3.46-3.37A3.43,3.43,0,0,1,31.91,57.21Zm10.78-2.43a3.42,3.42,0,1,1,1.66-4.54A3.42,3.42,0,0,1,42.69,54.78Zm8.66-6.86a3.42,3.42,0,1,1-.47-4.81A3.42,3.42,0,0,1,51.35,47.92Z" transform="translate(0 0)" style={{fill:dialerColor}}/>
                                                <circle data-name="hole" cx="32.01" cy="10.96" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="22.72" cy="13.07" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="15.26" cy="19" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.11" cy="27.59" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.1" cy="37.12" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="15.23" cy="45.71" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="22.67" cy="51.66" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="31.96" cy="53.79" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="41.25" cy="51.68" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="48.71" cy="45.75" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="48.74" cy="19.05" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="41.3" cy="13.1" r="3.42" style={{fill: holeColor}}/>
                                                <circle data-name="center" cx="31.98" cy="32.38" r="15.56" style={{fill:centerColor}}/>
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <path data-name="pointer"  d="M32,32.39l25.83-10.6a28,28,0,0,1,0,21.22Z" transform="translate(0 0)" style={{fill:pointerColor}}/>
                                        </svg>
                                    </>}

                                    {size=='32' &&
                                    <>
                                        

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{title}</title>
                                            <rect data-name="background" width="32" height="32" rx="2.97" style={{fill: bgColor}}/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <g data-name="holes">
                                                <path data-name="dialer" d="M16,2.39A13.79,13.79,0,1,0,29.77,16.2v0A13.78,13.78,0,0,0,16,2.39Zm7.49,6a1.45,1.45,0,1,1-.23,2l0,0a1.46,1.46,0,0,1,.24-2Zm.29,7.91A7.78,7.78,0,1,1,16.1,8.42h0a7.78,7.78,0,0,1,7.65,7.86Zm-4-10.89a1.45,1.45,0,1,1-.23,2l0,0a1.46,1.46,0,0,1,.23-2ZM15.11,4.33a1.45,1.45,0,1,1-.23,2l0,0A1.46,1.46,0,0,1,15.11,4.33ZM10.46,5.39a1.45,1.45,0,1,1-.26,2,1.45,1.45,0,0,1,.26-2Zm-3.72,3a1.45,1.45,0,1,1-.23,2l0,0a1.43,1.43,0,0,1,.24-2l0,0ZM4.66,12.63a1.45,1.45,0,1,1-.23,2l0,0A1.46,1.46,0,0,1,4.66,12.63Zm-.25,6.81a1.45,1.45,0,1,1,2,.22l0,0A1.45,1.45,0,0,1,4.41,19.44ZM8.5,24a1.45,1.45,0,1,1,.23-2l0,0A1.46,1.46,0,0,1,8.5,24Zm3.72,3a1.45,1.45,0,1,1,.23-2l0,0A1.45,1.45,0,0,1,12.22,27Zm4.66,1.07A1.46,1.46,0,1,1,17.09,26l0,.05a1.46,1.46,0,0,1-.25,2ZM21.51,27a1.45,1.45,0,1,1,.22-2l0,0A1.45,1.45,0,0,1,21.51,27Zm3.72-3a1.45,1.45,0,1,1,.23-2l0,0A1.46,1.46,0,0,1,25.23,24Z" style={{fill:dialerColor}}/>
                                                <circle data-name="hole" cx="24.36" cy="9.52" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="20.64" cy="6.54" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="16" cy="5.48" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.35" cy="6.54" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="7.63" cy="9.5" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="5.55" cy="13.79" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="5.55" cy="18.55" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="7.61" cy="22.86" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="11.33" cy="25.82" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="15.98" cy="26.88" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="20.61" cy="25.83" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="24.34" cy="22.86" r="1.45" style={{fill: holeColor}}/>
                                                <circle data-name="center" cx="15.98" cy="16.18" r="7.77" style={{fill: holeColor}}/>
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <path d="M15.82,16.19,28.9,10.82a14.21,14.21,0,0,1,0,10.75Z"  transform="translate(0 -0.02)" style={{fill:pointerColor}}/>
                                        </svg>

                                    </>}


                                    {size=='16' &&
                                    <>
                                        
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            viewBox="0 0 16 16"
                                            className={`h-full w-full absolute `}
                                        >
                                            <title>{title}</title>
                                            <path data-name="background" d="M1.49,0h13A1.49,1.49,0,0,1,16,1.49v13A1.48,1.48,0,0,1,14.52,16h-13A1.48,1.48,0,0,1,0,14.52v-13A1.49,1.49,0,0,1,1.49,0Z" style={{fill: bgColor}}/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                            className={`${classAnimate} h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <g data-name="holes">
                                                <path 
                                                    d="M8,1.2a6.89,6.89,0,1,0,6.89,6.9h0A6.88,6.88,0,0,0,8,1.2Zm2.34,1.56a.51.51,0,0,1,0,1,.51.51,0,0,1,0-1Zm1.54,5.38h0A3.89,3.89,0,1,1,8.05,4.21h0A3.9,3.9,0,0,1,11.88,8.14ZM8,2.25a.51.51,0,0,1,0,1,.51.51,0,1,1,0-1Zm-2.34.51a.51.51,0,1,1-.51.51A.51.51,0,0,1,5.67,2.76ZM3.82,4.24a.51.51,0,1,1-.52.51A.51.51,0,0,1,3.82,4.24Zm-1,5.55a.52.52,0,1,1,.51-.52A.51.51,0,0,1,2.77,9.79Zm0-2.38a.52.52,0,0,1,0-1,.52.52,0,0,1,0,1Zm1,4.53a.51.51,0,1,1,.51-.51A.51.51,0,0,1,3.82,11.94Zm1.85,1.5a.52.52,0,1,1,.52-.51A.51.51,0,0,1,5.67,13.44ZM8,14a.51.51,0,1,1,.51-.51A.51.51,0,0,1,8,14Zm2.32-.55a.51.51,0,1,1,.51-.51A.51.51,0,0,1,10.3,13.42Zm1.86-1.48a.51.51,0,0,1,0-1,.51.51,0,0,1,0,1Zm0-6.67a.51.51,0,0,1,0-1,.51.51,0,0,1,0,1Z" 
                                                    transform="translate(0 0)" 
                                                    style={{fill:dialerColor}}
                                                />
                                                <circle cx="12.16" cy="4.76" r="0.51" fill="#0d1424"/>
                                                <circle data-name="hole" cx="10.34" cy="3.27" r="0.51"  style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="8.01" cy="2.76" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="5.68" cy="3.27" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="3.82" cy="4.75" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="2.78" cy="6.89" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="2.77" cy="9.27" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="3.82" cy="11.43" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="5.68" cy="12.93" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="7.98" cy="13.46" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="10.3" cy="12.91" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="hole" cx="12.16" cy="11.43" r="0.51" style={{fill: holeColor}}/>
                                                <circle data-name="center" cx="7.99" cy="8.09" r="3.89" style={{fill: centerColor}}/>
                                            </g>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"
                                            className={`h-full w-full absolute`}
                                        >
                                            <title>{title}</title>
                                            <path data-name="pointer" d="M7.91,8.09,14.45,5.4a7.13,7.13,0,0,1,0,5.37Z" transform="translate(0 0)" style={{fill:pointerColor}}/>
                                        </svg>




                                    </>}

                                       
                        </div>


                                        
                                        
    
    </>)
}